import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Entry from "./Entry";
import Live from "./Live";
import List from "./List";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <List /> }></Route>
        <Route path="/entry/:dlink" element={ <Entry /> }></Route>
        <Route path="/webinar/:dlink" element={ <Live /> }></Route>        
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
