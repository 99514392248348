import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { setCookie, getCookie } from "./CommonFunctions";
import loadingImage from './loading.gif';
import axios from "axios";

export default function Live() {

  let navigate = useNavigate();
  let { dlink } = useParams();
  let [webinar, setWebinar] = useState(null);
  let [loading, setLoading] = useState(true);
  let [enrollmentId, setEnrollmentId] = useState(0);
  let [liveCount, setLiveCount] = useState(0);
  let [attendeeDetails, setAttendeeDetails] = useState({});
  let [updateCount, setUpdateCount] = useState(0);

  useEffect(()=>{
    setLiveCount(Math.floor(Math.random() * (70 - 60 + 1)) + 60);
    axios.post(process.env.REACT_APP_BASE_URL + "wapi/webinar/" + dlink)
      .then((res) => {
        if (res.data.data.status == "success") {
          document.title = res.data.data.result.title;
          setWebinar(res.data.data.result);
        } else {
          navigate("/");
        }
    });
    let eid = getCookie("enrollmentid");
    setEnrollmentId(eid);
    axios.post(process.env.REACT_APP_BASE_URL + "wapi/attendeedetails/" + eid)
      .then((res) => {
        if (res.data.data.status == "success") {
          setAttendeeDetails(res.data.data.result);
        } else {
          navigate("/");
        }
      });

    let interval = setInterval(() => {
      setLoading(false);
      clearInterval(interval);
    }, 5000);    

    if(webinar != null){
      let updateInterval = setInterval(()=>{
        axios.post(process.env.REACT_APP_BASE_URL + "wapi/attendeeupdate/" + enrollmentId);
        setUpdateCount(updateCount + 1);
        if(updateCount > webinar.duration){
          clearInterval(updateInterval);
        }
      }, 60000);
    }
  }, []);

  function askToEnroll(e)
  {
    e.preventDefault();
    if(window.confirm('Want to send interest to learn more by enrolling in course?')){
      let message = "Hello iGAP, I am " + attendeeDetails.name + ". I attended your webinar on " + webinar.title + " and I am interested in further course.";
      let url = "https://api.whatsapp.com/send/?phone=919561320192&text=" + message;
      window.open(url,'_blank', 'rel=noopener noreferrer');
    }    
  }

  useEffect(()=>{
    if(webinar != null && !loading){
      let iframe = document.getElementById("myiframe");
      var player = new window.Vimeo.Player(iframe);
      player.setCurrentTime(0);
    }
  }, [webinar, loading]);

  return (
    <div className="container">      
      {
        loading && <div className="col-12 text-center">
          <img src={loadingImage} className="img-fluid" style={{ height:"200px" }} />
          <center><h2>Please wait, it's loading...</h2></center>
        </div>
      }
      {
        loading == false && <div>
          <div className="row mt-3">
            <div className="col-9">
              <h3>{ webinar.title }</h3>
            </div>
            <div className="col-3">
              <button type="button" className="btn btn-primary position-relative">
                  <i className="fa fa-users"></i>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {liveCount}+
                  </span>
                </button>
            </div>
          </div>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe id="myiframe"
            className="embed-responsive-item img-thumbnail"
            src={"https://player.vimeo.com/video/" + webinar.vimeocode}
            allowFullScreen />
        </div>
        <br />
        <div className="bg-white p-2" style={{ overflowY: "auto", height: "400px" }}>
          <div dangerouslySetInnerHTML={{__html:webinar.description}} />
          <div style={{ height:"100px" }}></div>
        </div>
        <nav className="navbar fixed-bottom navbar-light bg-dark">
          <div className="container-fluid">
            <div className="row " style={{width:"100%"}}>
              <div className="col-6 text-white">
                  Hello { attendeeDetails.name }.
              </div>
              <div className="col-6" style={{ textAlign:"right" }}>
                  <span onClick={(e)=>{askToEnroll(e)}} className="btn btn-sm btn-warning"><i className="fa fa-whatsapp text-success"></i> Interested?</span>
              </div>
            </div>
          </div>
        </nav>
      </div>
  }
    </div>
  );
}
