import axios from "axios";
import { useEffect, useState } from "react";

export default function List() {
  let [result, setResult] = useState([]);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_URL + "wapi/webinars")
      .then((res) => {
        if (res.data.data.status == "success") {
          setResult(res.data.data.result);
        } else {
          alert("Something went wrong");
        }
      });
  }, []);

  return (
    <div className="container p-4">
      <div className="row">
        {result.map((row) => {
          return (
            <div className="col-lg-4 p-2" key={row.id}>
              <div class="card">
                <img
                  class="card-img-top"
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "webinarpics/" +
                    row.imagename +
                    ".png"
                  }
                  alt="Card image"
                />
                <div class="card-body">
                  <center>
                    <h4 class="card-title">{row.title}</h4>
                    <a href={"/entry/" + row.dlink } class="btn btn-primary">
                      Click To Attend
                    </a>
                  </center>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
