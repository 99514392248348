import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { setCookie, getCookie } from "./CommonFunctions";

export default function Entry() {
  let navigate = useNavigate();
  let { dlink } = useParams();
  let [webinar, setWebinar] = useState({});
  let [data, setData] = useState({
    id:0,
    name: getCookie("name"),
    email: getCookie("email"),
    mobileno: getCookie("mobileno"),
  });
  let [dataError, setDataError] = useState({
    name: "",
    email: "",
    mobileno: "",
  });

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_URL + "wapi/webinar/" + dlink)
      .then((res) => {
        if (res.data.data.status == "success") {
          setWebinar(res.data.data.result);
          setData({...data, id:res.data.data.result.id});
        } else {
          navigate("/");
        }
      });
  }, []);

  function handleChange(e) {
    e.preventDefault();
    setData({ ...data, [e.target.id]: e.target.value });
    setDataError({ ...dataError, [e.target.id]: "" });
  }

  function handleSubmit(e) {
    e.preventDefault();
    let nameError = "";
    let emailError = "";
    let mobilenoError = "";
    let errorExists = false;
    if (data.name.trim() == "") {
      nameError = "Enter name";
      errorExists = true;
    }
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      emailError = "Enter valid email";
      errorExists = true;
    }
    if (!/^[0-9]{10}$/.test(data.mobileno)) {
      mobilenoError = "Enter 10 digit whatsapp no";
      errorExists = true;
    }
    setDataError({
      name: nameError,
      email: emailError,
      mobileno: mobilenoError,
    });
    if (!errorExists) {
      setCookie("name", data.name, 1);
      setCookie("email", data.email, 1);
      setCookie("mobileno", data.mobileno, 1);
      const formData = new FormData();
      for (let key in data) {
          formData.append(key, data[key]);
      }
      //Call API      
      axios.post(process.env.REACT_APP_BASE_URL + "wapi/enrollment", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        if (res.data.data.status == "success") {
          setCookie("enrollmentid", res.data.data.result, 1);
          navigate("/webinar/"  + dlink);
        } else {
          alert("Something went wrong");
        }
      }).catch((ex)=>{
        console.log(ex);
      });
    }
  }

  return (
    <div className="col-lg-4 p-3 mx-auto mb-5">
      <main className="form-signin w-100 m-auto">
        <center>
          <img
            className="img-fluid mb-4"
            src={
              process.env.REACT_APP_BASE_URL +
              "webinarpics/" +
              webinar.imagename +
              ".png"
            }
            alt=""
          />
          <h2 className="m-2">{webinar.title}</h2>
        </center>
        <div className="form-floating">
          <input
            type="text"
            className="form-control"
            id="name"
            value={data.name}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <label htmlFor="name">
            Name*{" "}
            <span className="text-danger text-small">{dataError.name}</span>
          </label>
        </div>
        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="email"
            value={data.email}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <label htmlFor="email">
            Email*{" "}
            <span className="text-danger text-small">{dataError.email}</span>
          </label>
        </div>
        <div className="form-floating">
          <input
            type="number"
            className="form-control"
            id="mobileno"
            value={data.mobileno}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <label htmlFor="mobileno">
            WhatsApp No(To Receive OTP)*{" "}
            <span className="text-danger text-small">{dataError.mobileno}</span>
          </label>
        </div>
        <button
          className="btn btn-primary btn-lg w-100 py-2 mt-2"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Enter Webinar
        </button>
      </main>
    </div>
  );
}
